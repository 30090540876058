/* CHECKOUT COMPONENT */
/* MAIN GEDEELTE */
.container-main {
  display: grid;
  grid-template-areas:
    "Reserveringen"
    "Totaalprijs";
  grid-row-gap: 30px;
  margin-top: 1.4rem;
}

@media (min-width: 1170px) {
  .container-main {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 100px;
    grid-template-areas:
      "Reserveringen Totaalprijs";
    /* justify-content: center; */
  }
}

/* RESERVERINGEN GEDEELTE */
.container-reserveringen {
  display: grid;
  grid-template-columns: subgrid;
  grid-template-areas: 
    "header"
    "dropdown"
    "image"
    "info"
  ;
}

.reserveringen-header {
  grid-row: 1 / 1;
  grid-column: 1 / 4;
  text-align: left;
}

.item-b {
  grid-column: 1 / 4;
}

.item-c {
  grid-column: 1 / 4;
  justify-self: center;
}

.item-d {
  grid-column: 1 / 4;
  text-align: left;
}

@media (min-width: 815px) {
  .container-reserveringen {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "header header header"
    "dropdown image info";
  }
  .item-b {
  grid-area: dropdown
  }
  .item-c {
  grid-area: image;
  }
  .item-d {
    grid-area: info;
    text-align: left;
  }  
}

/* TOTAALPRIJS GEDEELTE */
.container-totaalprijs {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.totaalprijs-header  {
  grid-row: 1 / 1;
  grid-column: 1 / 3;
  text-align: left;
}

.item-f {
  text-align: left;
}

.horizontal-line {
  grid-column: 1 / 3;
}

.item-h {
  grid-column: 1 / 1;
  text-align:  left;
}

.item-i {
  grid-column: 2/2;
}

.item-j {
  grid-column: 1/3;
}

hr {
  height: 2px;
  background-color: var(--joepark-green);
  border: 0 none;
}

.btn-eventdetails {
  background-color: var(--joepark-blue);
  border-color: white;
  color: #fff;
}

.btn-eventdetails:hover {
    color: #fff;
    background-color: #258083;
    border-color: white;
}

.password-field {
    grid-column: 1 / 3;
}