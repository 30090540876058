/* CSS color variables */
:root {
  --main-bg-color: brown;
  --joepark-blue: #2c8d90;
  --joepark-green: #bee9d9;
  --joepark-grey: #f1f1f1;
  --joepark-grey2: #dbdbe4;
  --joepark-grey3: #bfbdbd;
  --joepark-darkgreen: #3d4a44;
  --joepark-lightgreen: #76807a;
}

/* Event cards */
img {
  max-width: 100%;
  display: block;
}
.listing {
  list-style: none;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  padding: 0;
  margin: 0;
}
.listing li {
  display: flex;
  flex-direction: column;
}
.card-img-top-custom {
  min-height: 300px;
  object-fit: cover;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card.card-event {
  border: 0;
  border-radius: 0;
}
.event-icon {
  width: 20px;
}

/* Event Cards */
.list-group-event {
  padding: 1rem;
}
.list-group-item-event {
  background-color: inherit !important;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
}
a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
