$primary: #bce9d9;
.faq-wrapper {
    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    /* Accordion styles */
    .tabs {
        overflow: hidden;
    }

    .tab {
        width: 100%;
        color: black;
        overflow: hidden;

        &:first-child {
            border-top: none;

            .tab-label {
                border-top: none;
            }
        }

        &-label {
            display: flex;
            justify-content: space-between;
            padding: 1em;
            font-weight: bold;
            cursor: pointer;
            margin-bottom: 0;

            border-top: 1px solid rgba(black, 0.1);
            border-bottom: 1px solid rgba(black, 0.1);

            /* Icon */
            &:hover {
                border-bottom: 1px solid darken($primary, 10%);
            }

            &::after {
                content: "\276F";
                width: 1em;
                height: 1em;
                text-align: center;
                transition: all .35s;
            }
        }

        &-content {
            max-height: 0;
            padding: 0 1em;
            color: black;
            background: white;
            transition: all .35s;
            a{
                font-weight: bold;
                text-decoration: underline !important;
            }
        }

    }

    // :checked
    input:checked {
        +.tab-label {
            border-bottom: 1px solid darken($primary, 10%);

            &::after {
                transform: rotate(90deg);
            }
        }

        ~.tab-content {
            max-height: 100vh;
            padding: 1em;
        }
    }
}