/* CSS color variables */
:root {
  --main-bg-color: brown;
  --joepark-blue: #2c8d90;
  --joepark-green: #bee9d9;
  --joepark-grey: #f1f1f1;
  --joepark-grey2: #dbdbe4;
  --joepark-grey3: #bfbdbd;
  --joepark-darkgreen: #3d4a44;
  --joepark-lightgreen: #76807a;  
}

/* From https://css-tricks.com/design-considerations-text-images/ */
.jumbotron-eventdetails > header {
  position: absolute;
  bottom: 0;
  left: 0;
  /* width: 100%; */
  padding: 20px 30px;
}

.jumbotron-eventdetails > header > h1 {
  margin: 0;
  color: white;
  text-shadow: 0 1px 0 black;
}

.jumbotron-eventdetails {
  background-size: cover;
  border-radius: 0px;
  background-position: center center;
  height: 300px;
  bottom: -50px;
  position: relative;
  text-align: center;
}

/* The container */
.container-radiobutton {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radiobutton input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radiobutton input + div {    
  border: 2px solid var(--joepark-green);
  border-radius: 12px;
  /* background-color: #ccc; */
}

/* When the radio button is checked, add a blue background */
.container-radiobutton input:checked + div {
  background: var(--joepark-green);
  border-radius: 12px;
  transition: 0.2s ease-in-out;
  font-weight: bold;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  border: 2px solid white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radiobutton input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radiobutton .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.btn-eventdetails {
  background-color: var(--joepark-blue);
  border-color: white;
  color: #fff;
}

.btn-eventdetails:hover {
    color: #fff;
    background-color: #258083;
    border-color: white;
}