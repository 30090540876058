/* Venue cards */
.card-body-venues {
  background-color: var(--joepark-grey);
}
.venue-card-button {
  background-color: white;
  border: 1px solid var(--joepark-green);
  border-radius: 7px;
  width: 50%;
  position: relative;
  bottom: 25px;
  align-self: center;
  text-align: center;
  height: 3.2rem;
  line-height: 3.1rem;
}
.venue-card-button:hover {
  background-color: var(--joepark-blue);
  color: white;
  border-color: var(--joepark-blue);
  cursor: pointer;
  transition: 0.3s ease-in-out;
}