.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Site-content {
  flex: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a,
u {
  text-decoration: none !important;
}

a {
  color: inherit;
}

/* CSS color variables */
:root {
  --main-bg-color: brown;
  --joepark-blue: #2c8d90;
  --joepark-green: #bee9d9;
  --joepark-grey: #f1f1f1;
  --joepark-grey2: #dbdbe4;
  --joepark-grey3: #bfbdbd;
  --joepark-darkgreen: #3d4a44;
  --joepark-lightgreen: #76807a;
}

/* Venue cards */
.card-body-venues {
  background-color: var(--joepark-grey);
}
.venue-card-button {
  background-color: white;
  border: 1px solid var(--joepark-green);
  border-radius: 7px;
  width: 50%;
  position: relative;
  bottom: 25px;
  align-self: center;
  text-align: center;
  height: 3.2rem;
  line-height: 3.1rem;
}
.venue-card-button:hover {
  background-color: var(--joepark-blue);
  color: white;
  border-color: var(--joepark-blue);
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.bg--grey {
  background-color: var(--joepark-grey);
}

.box {
  outline: 3px solid red;
}
